.designs{
    position: relative;
    height: 400vh;
    width: 100%;
    background: linear-gradient(
        to top left,
        #3C4956 0%,
        black 100%
      );
    display: flex;
    flex-direction: column;
}

*:focus {
    outline: none;
  }

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.designs-header{
    z-index:3;
    font-family: 'IBM Plex Mono', monospace;
    font-size: xx-large;
    position: absolute;
    top:80px;
    left:15vw;
    height:106px;
    color: #71E0F4;
}

.design-slider-container {
    z-index:3;
    max-width: 750px;
    max-height: 100%;
    margin-left: 10%;
    margin-bottom: 10%;
    margin-top: 14%;
  }


    .design-card {
      border-radius: 10px;
      border: 0;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      padding: 20px;
      text-align: center;
      height: 60vh;
      margin: auto;
      width:18vw;
      transition: transform 0.3s ease-in-out;
    }

    .design-image{
        width: 90%;
        height: 100%;
        object-fit: cover;
        border: 2px solid #e2c550;
    }

    .business-card{
        border: 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        text-align: center;
        transition: transform 0.3s ease-in-out;
        
      }
  
      .business-image{
          width: 90%;
          height: 90%;
          object-fit: cover;
          margin: auto;
          padding: auto;
          border: 2px solid #e2c550;
      }

    .designs-intro{
        width:400px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        padding: 30px 20px;
        height: 60%;
        border-radius: 12%;
        color: #e2c550;
        margin: 14% 6%;
        max-height: 700px;
        text-overflow: clip;
        font-size: auto;
        span{
            text-decoration: underline;
            font-size: large;
        }
        
    }

    .business-card-intro{
        width:400px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        padding: 30px 20px;
        height: 60%;
        border-radius: 12%;
        color: #e2c550;
        margin: 14% 6%;
        max-height: 700px;
        text-overflow: clip;
        font-size: auto;
        span{
            text-decoration: underline;
            font-size: large;
        }
        
    }

    .designs-content{
        display: flex;
        height: 100vh;
        flex-direction:row ;
        justify-content: center;
    }

    .business-card-content{
        display: flex;
        height: 80vh;
        flex-direction: row ;
        justify-content: center;
        align-items: center;
        margin-top: -16vh;
        margin-bottom:10vh;
        .design-slider-container{
            margin:2% 2%;
            max-width: 750px;
            max-height: 50vw;
          }
    }
    .logo-content{
        display: flex;
        height: 100vh;
        flex-direction:row ;
        justify-content: center;
    }

    .logos-card {
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        padding: 20px;
        height: 400px;
        margin: 0 6px;
        width:20vw;
      }
  
      .logos-image{
          width: 90%;
          height: 100%;
          object-fit: cover;
          border: 2px solid #e2c550;
      }

      .logos-intro{
        width:27%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        padding: 30px 20px;
        height: 60%;
        border-radius: 12%;
        color: #e2c550;
        margin: 14% 0;
        font-size: auto;
        max-height: 400px;
        span{
            text-decoration: underline;
            font-size: large;
        }
    }

    .logo-content{
        .design-slider-container{
            margin:8%;
            margin-right: 0;
        }
        .logos-intro{
            margin-top:8%        
        }
    }

    .interior{
        z-index:3;
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    
    .interior-container {
        z-index:4;
        display: flex;
        margin:0 10px;
        margin-top: 20px;
        max-width: 100%;
        max-height: 84%;
        align-items: center;
        overflow: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        transition: scroll 0.3s ease-in-out;
    
      }


    
    
      .image {
        z-index:4;
        max-width: 50vw;
        height: 96%;
        margin: 8px;
        border-radius: 8px;
        border: 2px solid #e2c550;
        
      }
    
      .interior-intro{
        text-align: center;
        width:80%;
        color: #e2c550;
        font-size: x-large;
        margin-top: 30px;
        span{
            text-decoration: underline;
            font-size: large;
        }
        
    }

    .design-image, .logos-image{
        cursor: pointer;
    }
    
    
    
    .interior-container::-webkit-scrollbar {
        width: 12px;
      }
      
      .interior-container::-webkit-scrollbar-thumb {
        background-color: #3C4956; 
      }
      
      .interior-container::-webkit-scrollbar-track {
        background-color: rgba(0,0,0,0.3); 
      }


@media (max-width:1367px){
    .designs-intro{
        width: 400px;
        font-size: 10px;
        overflow: hidden;
    }
    .business-card-intro{
        width: 400px;
        font-size: 10px;
        overflow: hidden;
    }
}

/* .design-image.zoomed {
    position: fixed;
    height: auto;
    top:20vh;
    left:20vw;
    width: auto;
  } */

  .modal-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .modal-close {
    position: fixed;
    top: 0;
    left:0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    border: none;
  }
  .modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border:4px solid #e2c550;
    box-shadow:0 0 20px #000000ae;
    max-width: 80vw; /* Adjust the max-width as needed */
    max-height: 80vh; /* Adjust the max-height as needed */
    overflow: auto;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }



    .overlay{
        position: fixed;
        top:0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #000000ae;
        z-index: 50;
    }





@media (max-width:1367px){
    .designs-intro{
        width: 400px;
        font-size: 10px;
        overflow: hidden;
    }
    .business-card-intro{
        width: 400px;
        font-size: 10px;
        overflow: hidden;
    }
    .design-slider-container{
        width: 60vw;
    }
    .logos-intro{
        margin: auto;
        max-width: 300px;
        font-size: 10px;
        height: 80%;
        max-height: 50vh;
        overflow: hidden;
    }
    .logo-content{
        .design-slider-container{
            margin:auto;
        }
        .logos-intro{
            margin-top:10%        
        }
        .logos-image{
            height: 90%;
        }
    }
}

@media (max-width:768px) {

    .designs{
        height: auto;
    }
    .designs-content{
        margin-top: 130px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: auto;
    }
    .design-slider-container {
        max-width: 60vw;
        height: 200px;
        margin: auto;
        margin-bottom: 40px;
    }
    .design-card{
        height: 160px;
        margin: auto;
    }
    .designs-intro{
        margin-top: 40px;
        width: 80%;
        margin: 0;
        height:160px;
        font-size: 10px;
    }

    .business-card-intro{
        margin-top: 40px;
        width: 80%;
        margin: 0;
        height:160px;
        font-size: 10px;
    }

    .logo-content{
        margin-top: 80px;
        margin-bottom: 10vh;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
        height: auto;

        .design-slider-container{
            height: 30%;
            width: 70vw;
            margin: auto;
            padding: auto;
        }
    }
    .logos-intro{
        margin: 0;
        width: 80%;
        height: 30%;
        font-size: 10px;
    }
    .logos-card{
        width: 100%;
        height: 20vh;
        margin: auto;
    }
    .logos-image{
        width:92%;
        height: 100%;
        margin:auto;
        /* object-fit: cover; */
    }

    .interior{
        height: auto;
        margin-bottom: 40px;
    }
    .image {
        max-width: 200vw;
        height: 100%;
        margin: 8px;
        border-radius: 8px;
        border: 2px solid #e2c550;
      }

    .interior-container{
        margin-top:20px;
        height: 60vh;
    }
    .interior-intro{
        margin-bottom: 20px;
    }

    .business-card-content{
        margin-top: 80px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: auto;
        .design-slider-container{
            margin-bottom:14%;
        }
        
    }
    .business-card{
        margin-bottom: 40px;
    }

  }